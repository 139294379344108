/* Login CSS */
.login-body {
  font-family: "Poppins";
  display: flex;
  /* height: 100vh; */
  position: relative;
  color: #1a1a1a;
}

.welcomebox {
  color: white;
  width: 100%;
  min-height: 100vh;
  padding: 0px;
  opacity: 0.82;
  /* display: flex; */
  align-content: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-image: url("../../src/welcomeImage.png");
}

.contentbox,
.tagline-container {
  position: absolute;
  /* padding: 8px;
  top: 30%; */
}

.company-label,
.tagline {
  /* text-align: center; */
  padding: 8%;
  font-size: 32px;
  font-weight: 500;
}

.company-tagline {
  font-size: 20px;
  font-weight: 500;
}

.loginbox {
  width: 100%;
  align-items: center;
  /* padding: 16px; */
  /* padding:10% 0; */
  margin: auto;
}

.loginbox-top {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 40%; */
  /* margin: 12px 0px; */
}

.loginbox-middle {
  margin: 16px 0px;
}

/* Login CSS */
.login-body {
  font-family: "Poppins";
  display: flex;
  /* height: 100vh; */
  position: relative;
  color: #1a1a1a;
}

/* .welcomebox {
  color: white;
  width: 100%;
  height: 100%;
  padding: 0px;
  opacity: 0.82;
  display: flex;
  align-content: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-image: url("../bg1.png");
} */

/* .company-label {
  text-align: center;
  padding: 40px;
  font-size: 32px;
  font-weight: 500;
} */

.company-tagline {
  font-size: 20px;
  font-weight: 500;
}

.loginbox {
  width: 100%;
  align-items: center;
  /* padding: 16px; */
  /* padding:10% 0; */
  margin: auto;
}

.loginbox-top {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 40%; */
  /* margin: 12px 0px; */
}

.loginbox-middle {
  margin: 16px 0px;
}

.loginbox-bottom {
  margin: 16px 0px;
}
.back-button-container {
  position: absolute;
  top: -20%;
  cursor: pointer;
  left: 0;
  margin-left: 5%;
}
.back-button-container2 {
  position: fixed;
  top: 10%;
  cursor: pointer;
  margin-left: 2%;
}

.companyheading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-name {
  font-weight: 500;
  font-size: 22px;
}

.welcomeText {
  padding: 8px 0px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: 500;
}

.signup {
  font-size: 14px;
  padding-top: 16px;
  color: #316ce2;
  cursor: pointer;
}

.loginGoogle {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  height: 30%;
  cursor: pointer;
}

#googlelogo {
  height: 46px;
}

.logginGoogleBox {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 2px 5px #d1caca; */
  border-radius: 4px;
  border: 1px solid #d1caca;
}
.signUpButton {
  text-align: center;
  margin: 1% 0;
  color: #928d8d;
}
.spanSignUpButton {
  color: #316ce2;
  font-weight: bold;
  cursor: pointer;
}
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  text-align: center;
  height: 1.5em;
  font-size: 12px;
}
.hr-text::before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text::after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 1.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: white;
}

.inputbox {
  width: 100%;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 12px;
  margin-bottom: 2%;
}

.inputbox > input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d1caca;
}

.customBlock{
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d1caca;
}

.customBlock > input{
  padding: 3% !important;
  border: none;
  width: 100%
}

.customBlock > input:focus{
  border: none;
  outline:0
}

.hideArrows > input:focus{
  outline: 0;
}

.inputbox > input::placeholder,
.customBlock > input::placeholder {
  color: #707070;
  font-size: 12px;
}

.hideArrows > input[type=number]::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

.inputText {
  text-align: left;
  padding-bottom: 4px;
}

.forgotPassword {
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #316ce2;
}

#login-button {
  width: 100%;
  margin: 14px 0px;
  color: white;
  font-size: 14px;
}

.login-button {
  width: 100%;
  /* margin: 14px 0px; */
  color: white;
  font-size: 14px;
}

.error {
  color: red;
  font-size: 12px;
  text-align: center;
}

/* Registration CSS */

.register {
  display: flex;
  justify-content: center;
  align-items: center;
}

#logintext {
  text-align: center;
  margin: 0;
  padding: 12px 8px;
  font-size: 20px;
  font-weight: 500;
}

#registrationText {
  text-align: center;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.inputbox {
  padding: 8px 12px;
}

#registrationForm {
  /* overflow: auto; */
  padding: 8px 0px;
  margin:0 auto;
  width:65%;
}

.editedScreeningQ {
  width: -webkit-fill-available;
}
/* Extra Additives */

.add-border {
  border: 1px #f7f3f3 solid;
  padding: 8px 14px;
  border-radius: 4px;
}

.validation-message {
  color: red;
  font-size: 13px;
  padding-left: 5px;
  margin: 0px;
}
/* Extra Additives */

/* OTP */
button {
  font-weight: 600;
  font-size: 14px;
  background-color: #316ce2;
  height: 40px;
  width: auto;
  padding: 0 16px;
  color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.timer {
  font-size: 18px;
  align-items: center;
}

.send-otp {
  margin-left: 16px;
  float: right;
}

.logginGoogleBox > button {
  width: 100%;
  justify-content: center;
  display: flex !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.logginGoogleBox > button:focus {
  outline: none;
}

.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
}

.empty-team {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
}

.empty-team-box {
  width: 300px;
  height: 120px;
  border: 1px solid #e0e6ed;
}

.empty-team-box-header {
  display: flex;
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  padding: 4px;
  border-bottom: 1px solid #e0e6ed;
  height: 30%;
}

.empty-team-box-body {
  display: flex;
  width: 100%;
  height: 70%;
  padding: 4px 8px;
}
hr.line {
  color: black;
  padding: 0;
  margin: 0;
}

.input-btn {
  background-color: #0d47a1;
  color: white;
}

.select-user-type {
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 6px 8px;
}
.inputBlock {
  padding: 3% !important;
  width:100%;
}
.companyName {
  width: 100%;
  margin: 10% 0;
}
.poweredBy {
  text-align: center;
  color: #928d8d;
  font-weight: 400;
  font-size: 15px;
}
.comName {
  color: #316ce3;
  font-weight: bold;
}
.login-button {
  height: 60px;
  background: #417ef7;
}

#login-box {
  margin: auto;
  top: 50%;
  width: 100%;
  transform: translate(10px, -50%);
  position: absolute;
}

/* .data-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.resendOtp {
  text-align: center;
  font-size: 16px;
  font-weight: 100;
}
.customSvg{
  transform: translate(-5px, 8px);
  cursor: pointer;
}
input[type="checkbox"] {
  box-sizing: border-box !important;
  padding: 0px !important;
  vertical-align: middle !important;
}
.checkBoxLabel {
  
  font-size: 13px !important;
  margin: 12px 4px!important;
}

