body {
  height: 100vh;
  overflow-x: hidden;
}

#root {
  background-color: transparent;
  height: 100%;
  color: #1a1a1a;
  font-family: "Poppins";
}
