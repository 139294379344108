.loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  animation-delay: 1s;
}

.item-1 {
  width: 8px;
  height: 8px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #a5bff3;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50%,
  75% {
    transform: scale(2.5);
  }
  78%,
  100% {
    opacity: 0;
  }
}
.item-1:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #a5bff3;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 200ms;
  transition: 0.5s all ease;
  transform: scale(1);
}

.item-2 {
  width: 8px;
  height: 8px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #779ff5;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50%,
  75% {
    transform: scale(2.5);
  }
  78%,
  100% {
    opacity: 0;
  }
}
.item-2:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #779ff5;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 400ms;
  transition: 0.5s all ease;
  transform: scale(1);
}

.item-3 {
  width: 8px;
  height: 8px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #5cadef;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50%,
  75% {
    transform: scale(2.5);
  }
  78%,
  100% {
    opacity: 0;
  }
}
.item-3:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #5cadef;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 600ms;
  transition: 0.5s all ease;
  transform: scale(1);
}

.item-4 {
  width: 8px;
  height: 8px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #4a81ee;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50%,
  75% {
    transform: scale(2.5);
  }
  78%,
  100% {
    opacity: 0;
  }
}
.item-4:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #4a81ee;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 800ms;
  transition: 0.5s all ease;
  transform: scale(1);
}

.item-5 {
  width: 8px;
  height: 8px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #316ce2;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50%,
  75% {
    transform: scale(2.5);
  }
  78%,
  100% {
    opacity: 0;
  }
}
.item-5:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #316ce2;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 1000ms;
  transition: 0.5s all ease;
  transform: scale(1);
}
